<template>
  <div>
    <p class="learn-more-and-help-title mt-0">
      {{ $t('learnMoreAndHelp.title') }}
    </p>

    <v-row class="d-flex">
      <v-col
        class="d-flex"
        :cols="12"
        :lg="4"
      >
        <learn-more-and-help-card
          class="w-100"
          :title="`${$t('learnMoreAndHelp.helpCenter.title')}`"
          :subtitle="`${$t('learnMoreAndHelp.helpCenter.subtitle')}`"
          :img="`${require('@/assets/img/illustrations/learn-more-and-help/help-center.svg')}`"
          @click="onClickHelp"
        />
      </v-col>
      <v-col
        class="d-flex"
        :cols="12"
        :lg="4"
      >
        <learn-more-and-help-card
          class="w-100"
          :title="`${$t('learnMoreAndHelp.feedback.title')}`"
          :subtitle="`${$t('learnMoreAndHelp.feedback.subtitle')}`"
          :img="`${require('@/assets/img/illustrations/learn-more-and-help/feedback.svg')}`"
          @click="setDialogFeedbackState(true)"
        />
      </v-col>
      <v-col
        class="d-flex"
        :cols="12"
        :lg="4"
      >
        <learn-more-and-help-card
          class="w-100"
          :title="`${$t('learnMoreAndHelp.hireExpert.title')}`"
          :subtitle="`${$t('learnMoreAndHelp.hireExpert.subtitle')}`"
          :img="`${require('@/assets/img/illustrations/learn-more-and-help/hire-expert.svg')}`"
          @click="redirectToWebsite"
        />
      </v-col>
    </v-row>
  </div>

</template>

<script>
import LearnMoreAndHelpCard from '@/views/Home/IdeaHome/Components/LearnMoreAndHelp/LearnMoreAndHelpCard.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'LearnMoreAndHelp',

  components: { LearnMoreAndHelpCard },

  methods: {
    ...mapMutations('ui', ['setDialogFeedbackState']),

    onClickHelp () {
      this.$gtm.trackEvent({
        event: 'helpButton'
      })

      window.open(process.env.VUE_APP_HELP_URL, '_blank')
    },
    redirectToWebsite () {
      window.open('http://ideabuddy.com/business-plan-writing/', '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.learn-more-and-help-title {
  font-size: 24px;
  margin-bottom: 28px;
}
</style>
